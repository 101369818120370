html,
body {
  padding: 0;
  margin: 0;
  font-family: "Open Sans", sans-serif !important;
  background-color: #f7f9fc;
}

a {
  text-decoration: none;
  color: black;
}

* {
  box-sizing: border-box;
}
